
import { defaultQueryFn } from '../../../../queryapi';

import {
	globalStore,
	setFAdmCat_na,
	setFAdmCat_or,
	// setFAdmCat_ip,
	setFAdmCat_ai,
	setFAdmCat_si,
	setFAdmCat_en,
	setFAdmCat_de,
	setAllCategory,
} from '../../../../globalstate';

function AdminAddManageCategory_ctrl(forCtrler) {

	const checkErrorGeneral = (fromBtSave) => {
		let errorAll = false;

		if (forCtrler.errorEpty_na === true || forCtrler.category_na === "") {
			if (forCtrler.category_na === "" && fromBtSave) {
				forCtrler.setErrorEpty_na(true);
			}
			errorAll = true;
		}
		
		if (forCtrler.errorEpty_or === true || forCtrler.category_or === "0") {
			if (forCtrler.category_or === "0" && fromBtSave) {
				forCtrler.setErrorEpty_or(true);
			}
			errorAll = true;
		}
		
		if (forCtrler.errorEpty_si === true || forCtrler.category_si === "0") {
			if (forCtrler.category_si === "0" && fromBtSave) {
				forCtrler.setErrorEpty_si(true);
			}
			errorAll = true;
		}

		forCtrler.setErrorEpty_all(errorAll);
		forCtrler.errorEpty_all = errorAll;
	};

	const handleSaveCategoryAdd = async (event) => {
		event.preventDefault();

		checkErrorGeneral(true);

		if (forCtrler.errorEpty_all === false) {
			await defaultQueryFn({
				query: "categories",
				typeReq: "POST",
				args: {
					name: forCtrler.category_na,
					order: forCtrler.category_or,
					img_path: forCtrler.category_ip.signed_id,
					auto_img: forCtrler.category_ai,
					enable: forCtrler.category_en,
					section_id: forCtrler.category_si,
				},
				enable: true,
				special: null
			})
				.then( async (result) => {
					globalStore.dispatch(setAllCategory(
						result && result.data && result.data.result ? result.data.result : null
					));

					return result;
				})
				.catch((error) => {
					return error;
				})
		}
	};

	const handleSaveCategoryMod = async (event) => {
		event.preventDefault();

		checkErrorGeneral(true);

		if (forCtrler.errorEpty_all === false) {
			const query = "categories/" + forCtrler.category_old.id;

			if (forCtrler.category_de === true) {
				await defaultQueryFn({
					query: query,
					typeReq: "DELETE",
					args: {},
					enable: true,
					special: null
				})
					.then( async (result) => {
						globalStore.dispatch(setAllCategory(
							result && result.data && result.data.result ? result.data.result : null
						));

						return result;
					})
					.catch((error) => {
						return error;
					})
			} else {
				await defaultQueryFn({
					query: query,
					typeReq: "PUT",
					args: {
						name: forCtrler.category_na,
						order: forCtrler.category_or,
						img_path: forCtrler.category_ip.signed_id,
						auto_img: forCtrler.category_ai,
						enable: forCtrler.category_en,
						section_id: forCtrler.category_si,
					},
					enable: true,
					special: null
				})
					.then( async (result) => {
						globalStore.dispatch(setAllCategory(
							result && result.data && result.data.result ? result.data.result : null
						));

						return result;
					})
					.catch((error) => {
						return error;
					})
			}
		}
	};

	const handleChangeCategory_na = (event) => {
		// forCtrler.stateAddManCategory.setCategoryNew_na(event.target.value);
		if (event.target.value === "") {
			forCtrler.setErrorEpty_na(true);
		} else {
			forCtrler.setErrorEpty_na(false);
		}
		checkErrorGeneral(false);
		globalStore.dispatch(setFAdmCat_na(event.target.value));
	};

	const handleChangeCategory_or = (event) => {
		// forCtrler.stateAddManCategory.setCategoryNew_or(event.target.value);
		if (event.target.value === "0") {
			forCtrler.setErrorEpty_or(true);
		} else {
			forCtrler.setErrorEpty_or(false);
		}
		checkErrorGeneral(false);
		globalStore.dispatch(setFAdmCat_or(event.target.value));
	};

	const handleChangeCategory_ip = async (event) => {
		await defaultQueryFn({
				query: "NONE",
				typeReq: "FILE",
				args: event.target.files[0],
				enable: true,
				special: { typeItem: 'CATEGORY' }
			})
				.then((result) => {
					return result;
				})
				.catch((error) => {
					return error;
				})
		// globalStore.dispatch(setFAdmCat_ip(event.target.value));
	};

	const handleChangeCategory_ai = (event) => {
		if (event.target.value === 'N') {
			globalStore.dispatch(setFAdmCat_ai(false));
			forCtrler.setCatDisplay_ip(true);
		} else {
			globalStore.dispatch(setFAdmCat_ai(true));
			forCtrler.setCatDisplay_ip(false);
		}
	};

	const handleChangeCategory_si = (event) => {
		// forCtrler.stateAddManCategory.setCategoryNew_se(event.target.value);
		if (event.target.value === "0") {
			forCtrler.setErrorEpty_si(true);
		} else {
			forCtrler.setErrorEpty_si(false);
		}
		checkErrorGeneral(false);
		globalStore.dispatch(setFAdmCat_si(event.target.value));

	};

	const handleChangeCategory_en = (event) => {
		if (event.target.value === 'N') {
			// forCtrler.stateAddManCategory.setCategoryNew_en(false);
			globalStore.dispatch(setFAdmCat_en(false));
		} else {
			// forCtrler.stateAddManCategory.setCategoryNew_en(true);
			globalStore.dispatch(setFAdmCat_en(true));
		}
	};

	const handleChangeCategory_de = (event) => {
		// forCtrler.stateAddManCategory.setCategoryNew_de(event.target.checked);
		if (event.target.checked === true) {
			alert("Attention ! Vous venez de cocher la suppression, la sauvegarde supprimera cette catgorie ainsi que tous ses postes");
		}
		globalStore.dispatch(setFAdmCat_de(event.target.checked));
	};

	const putCategoryOrder = () => {
		let result = [];
		for (let i = 1; i <= forCtrler.maxOrdreP1; i++) {
			result.push(i);
		}
		return result;
	};

	// const category = {
	// 	name: forCtrler.stateAddManCategory.categoryNew_na,
	// 	order: forCtrler.stateAddManCategory.categoryNew_or,
	// 	img_path: forCtrler.stateAddManCategory.categoryNew_ip,
	// 	auto_img: forCtrler.stateAddManCategory.categoryNew_ai,
	// 	section: forCtrler.stateAddManCategory.categoryNew_se,
	// 	enable: forCtrler.stateAddManCategory.categoryNew_en,
	// 	is_delete: forCtrler.stateAddManCategory.categoryNew_de,
	// };	

	return {
		handleSaveCategoryAdd: handleSaveCategoryAdd,
		handleSaveCategoryMod: handleSaveCategoryMod,
		handleChangeCategory_na: handleChangeCategory_na,
		handleChangeCategory_or: handleChangeCategory_or,
		handleChangeCategory_ip: handleChangeCategory_ip,
		handleChangeCategory_ai: handleChangeCategory_ai,
		handleChangeCategory_si: handleChangeCategory_si,
		handleChangeCategory_en: handleChangeCategory_en,
		handleChangeCategory_de: handleChangeCategory_de,
		errorEpty_all: forCtrler.errorEpty_all,
		errorEpty_na: forCtrler.errorEpty_na,
		errorEpty_or: forCtrler.errorEpty_or,
		errorEpty_si: forCtrler.errorEpty_si,
		maxOrdreP1: forCtrler.maxOrdreP1,
		putCategoryOrder: putCategoryOrder,
		catDisplay_ip: forCtrler.catDisplay_ip,
		// category: category,
	};
} 

export default AdminAddManageCategory_ctrl;