
import { defaultQueryFn } from '../../../../queryapi';

import {
	globalStore,
	setFAdmIte_ti,
	setFAdmIte_ds,
	setFAdmIte_ta,
	setFAdmIte_co,
	setFAdmIte_or,
	// setFAdmIte_ip,
	setFAdmIte_ci,
	setFAdmIte_av,
	setFAdmIte_en,
	setFAdmIte_de,
	setAllItem,
	// setAllCategory,
} from '../../../../globalstate';

function AdminAddManageItem_ctrl(forCtrler) {

	const checkErrorGeneral = (fromBtSave) => {
		let errorAll = false;

		if (forCtrler.errorEpty_ti === true || forCtrler.item_ti === "") {
			if (forCtrler.item_ti === "" && fromBtSave) {
				forCtrler.setErrorEpty_ti(true);
			}
			errorAll = true;
		}
		
		if (forCtrler.errorEpty_or === true || forCtrler.item_or === "0") {
			if (forCtrler.item_or === "0" && fromBtSave) {
				forCtrler.setErrorEpty_or(true);
			}
			errorAll = true;
		}
		
		if (forCtrler.errorEpty_ip === true || forCtrler.item_ip === "") {
			if (forCtrler.item_ip === "" && fromBtSave) {
				forCtrler.setErrorEpty_ip(true);
			}
			errorAll = true;
		}
		
		if (forCtrler.errorEpty_ci === true || forCtrler.item_ci === "0") {
			if (forCtrler.item_ci === "0" && fromBtSave) {
				forCtrler.setErrorEpty_ci(true);
			}
			errorAll = true;
		}

		forCtrler.setErrorEpty_all(errorAll);
		forCtrler.errorEpty_all = errorAll;
	};

	const handleSaveItemAdd = async (event) => {
		event.preventDefault();

		checkErrorGeneral(true);

		if (forCtrler.errorEpty_all === false) {
			await defaultQueryFn({
				query: "items",
				typeReq: "POST",
				args: {
					title: forCtrler.item_ti,
					description: forCtrler.item_ds,
					tag: forCtrler.item_ta,
					path: forCtrler.item_ip.signed_id,
					carousel_order: forCtrler.item_co,
					order: forCtrler.item_or,
					available: forCtrler.item_av,
					enable: forCtrler.item_en,
					category_id: forCtrler.item_ci,
				},
				enable: true,
				special: null
			})
				.then( async (result) => {
					globalStore.dispatch(setAllItem(
						result && result.data && result.data.result ? result.data.result : null
					));

					// if (result && result.data && result.data.category_updated) {
					// 	forCtrler.categoryList.forEach((category) => {
					// 		if (category.id === result.data.category_updated.id) {
					// 			category = result.data.category_updated;
					// 		}
					// 	});
					// 	globalStore.dispatch(setAllCategory(forCtrler.categoryList));
					// }

					return result;
				})
				.catch((error) => {
					return error;
				})
		}
	};

	const handleSaveItemMod = async (event) => {
		event.preventDefault();

		checkErrorGeneral(true);

		if (forCtrler.errorEpty_all === false) {
			const query = "items/" + forCtrler.item_old.id;

			if (forCtrler.item_de === true) {
				await defaultQueryFn({
					query: query,
					typeReq: "DELETE",
					args: {},
					enable: true,
					special: null
				})
					.then( async (result) => {
						globalStore.dispatch(setAllItem(
							result && result.data && result.data.result ? result.data.result : null
						));

						return result;
					})
					.catch((error) => {
						return error;
					})
			} else {
				await defaultQueryFn({
					query: query,
					typeReq: "PUT",
					args: {
						title: forCtrler.item_ti,
						description: forCtrler.item_ds,
						tag: forCtrler.item_ta,
						path: forCtrler.item_ip.signed_id,
						carousel_order: forCtrler.item_co,
						order: forCtrler.item_or,
						available: forCtrler.item_av,
						enable: forCtrler.item_en,
						category_id: forCtrler.item_ci,
					},
					enable: true,
					special: null
				})
					.then( async (result) => {
						globalStore.dispatch(setAllItem(
							result && result.data && result.data.result ? result.data.result : null
						));

						return result;
					})
					.catch((error) => {
						return error;
					})
			}
		}
	};

	const handleChangeAItem_ti = (event) => {
		if (event.target.value === "") {
			forCtrler.setErrorEpty_ti(true);
		} else {
			forCtrler.setErrorEpty_ti(false);
		}
		checkErrorGeneral(false);
		globalStore.dispatch(setFAdmIte_ti(event.target.value));
	};

	const handleChangeAItem_ds = (event) => {
		globalStore.dispatch(setFAdmIte_ds(event.target.value));
	};

	const handleChangeAItem_ta = (event) => {
		globalStore.dispatch(setFAdmIte_ta(event.target.value));
	};

	const handleChangeAItem_co = (event) => {
		globalStore.dispatch(setFAdmIte_co(event.target.value));
	};

	const handleChangeAItem_or = (event) => {
		if (event.target.value === "0") {
			forCtrler.setErrorEpty_or(true);
		} else {
			forCtrler.setErrorEpty_or(false);
		}
		checkErrorGeneral(false);
		globalStore.dispatch(setFAdmIte_or(event.target.value));
	};

	const handleChangeAItem_ip = async (event) => {
		if (event.target.value === "") {
			forCtrler.setErrorEpty_ip(true);
		} else {
			forCtrler.setErrorEpty_ip(false);
		}
		checkErrorGeneral(false);

		await defaultQueryFn({
				query: "NONE",
				typeReq: "FILE",
				args: event.target.files[0],
				enable: true,
				special: { typeItem: 'ITEM' }
			})
				.then((result) => {
					return result;
				})
				.catch((error) => {
					return error;
				})
		// globalStore.dispatch(setFAdmIte_ip(event.target.value));
	};

	const handleChangeAItem_ci = (event) => {
		if (event.target.value === "0") {
			forCtrler.setErrorEpty_ci(true);
		} else {
			forCtrler.setErrorEpty_ci(false);
		}
		checkErrorGeneral(false);
		globalStore.dispatch(setFAdmIte_ci(event.target.value));
	};

	const handleChangeAItem_av = (event) => {
		if (event.target.value === 'N') {
			globalStore.dispatch(setFAdmIte_av(false));
		} else {
			globalStore.dispatch(setFAdmIte_av(true));
		}
	};

	const handleChangeAItem_en = (event) => {
		if (event.target.value === 'N') {
			globalStore.dispatch(setFAdmIte_en(false));
		} else {
			globalStore.dispatch(setFAdmIte_en(true));
		}
	};

	const handleChangeAItem_de = (event) => {
		if (event.target.checked === true) {
			alert("Attention ! Vous venez de cocher la suppression, la sauvegarde supprimera ce poste");
		}
		globalStore.dispatch(setFAdmIte_de(event.target.checked));
	};

	const putItemOrder = () => {
		let result = [];
		for (let i = 1; i <= forCtrler.maxOrdreP1; i++) {
			result.push(i);
		}
		return result;
	};

	const putItemCarouOrder = () => {
		let result = [];
		for (let i = 1; i <= forCtrler.maxCarouOrdreP1; i++) {
			result.push(i);
		}
		return result;
	};

	return {
		handleSaveItemAdd: handleSaveItemAdd,
		handleSaveItemMod: handleSaveItemMod,
		handleChangeAItem_ti: handleChangeAItem_ti,
		handleChangeAItem_ds: handleChangeAItem_ds,
		handleChangeAItem_ta: handleChangeAItem_ta,
		handleChangeAItem_co: handleChangeAItem_co,
		handleChangeAItem_or: handleChangeAItem_or,
		handleChangeAItem_ip: handleChangeAItem_ip,
		handleChangeAItem_ci: handleChangeAItem_ci,
		handleChangeAItem_av: handleChangeAItem_av,
		handleChangeAItem_en: handleChangeAItem_en,
		handleChangeAItem_de: handleChangeAItem_de,
		errorEpty_all: forCtrler.errorEpty_all,
		errorEpty_ti: forCtrler.errorEpty_ti,
		errorEpty_or: forCtrler.errorEpty_or,
		errorEpty_ip: forCtrler.errorEpty_ip,
		errorEpty_ci: forCtrler.errorEpty_ci,
		maxOrdreP1: forCtrler.maxOrdreP1,
		maxCarouOrdreP1: forCtrler.maxCarouOrdreP1,
		putItemOrder: putItemOrder,
		putItemCarouOrder: putItemCarouOrder,
	};
} 

export default AdminAddManageItem_ctrl;
