
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Getter } from '../../../generaltools';

import {
	globalStore,
	getAboutParagraphs,
} from '../../../globalstate';

import { HelmetComp } from '../../compoment/';

import {
	StyAboutPlaceDiv,
	StyAboutContentParaPlaceDiv,
	StyAboutContentParaContentDiv,
	StyAboutContentParaContentImgDiv,
	StyAboutContentParaContentImgImg,
	StyAboutContentParaContentTextP,
	StyGeneralButtonAddButton,
	StyAboutPageHeader,
	StyAboutPageH1,
} from '../../../style/';

import { About_ctrl } from '../../../controler/';

function About({location, aboutText, onClickAboutText, onClickAddAboutText}) {

	const about_text = useSelector(getAboutParagraphs(globalStore.getState())) || [];
	
	const headerPage = Getter.getHeaderPage({pageName: 'ABOUT', aboutParas: about_text});

	const forCtrler = {
		location: location,
		about_text: about_text,
	};
	const ctrler = About_ctrl(forCtrler);

	useEffect(() => {
		ctrler.getAboutParagraph();
/* eslint-disable */
	}, []);


	return (
		<StyAboutPlaceDiv>
			<HelmetComp
				title = {headerPage.title}
				description = {headerPage.descr}
				image = {headerPage.img} />
			{
				location === 'ADMIN' ? (
					<StyGeneralButtonAddButton onClick={onClickAddAboutText}>Ajout Paragraphe</StyGeneralButtonAddButton>
				) : (
					<StyAboutPageHeader>
						<StyAboutPageH1>{headerPage.title}</StyAboutPageH1>
						<p>{headerPage.descr}</p>
					</StyAboutPageHeader>
				)
			}
			{
				about_text.length !== 0 ? (
					about_text.map((about_text_item, liId) => {
						liId++;
						if (about_text_item.enable === true || (location === 'ADMIN')) {
							return (
								<StyAboutContentParaPlaceDiv key={liId}>
									{ ctrler.putTitleForce(about_text_item.id, about_text_item.title_force, about_text_item.title, onClickAboutText, location) }
									{
										location !== 'ADMIN' ? (
											<StyAboutContentParaContentDiv>
												{
													about_text_item.img_path && about_text_item.img_path !== '' ? (
														<StyAboutContentParaContentImgDiv><picture><StyAboutContentParaContentImgImg src={Getter.getImgPath(about_text_item.img_path)} alt={about_text_item.title} /></picture></StyAboutContentParaContentImgDiv>
													) : (
														<div></div>
													)
												}
												<StyAboutContentParaContentTextP>{about_text_item.content}</StyAboutContentParaContentTextP>
											</StyAboutContentParaContentDiv>
										) : ( <div></div> )
									}
								</StyAboutContentParaPlaceDiv>
							)
						} else {
							return (<div key={liId}></div>)
						}
					})
				) : (<div></div>)
			}
		</StyAboutPlaceDiv>
	);
} 

export default About;