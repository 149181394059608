
import {
	globalStore,
	setCurrentItem,
	setCurrentCategory,
	setCurrentSection,
	setAdminCurrCategory,
	setAdminIsActive,
	setAdminModeAddMod,

	setFAdmCat_na,
	setFAdmCat_or,
	setFAdmCat_ip,
	setFAdmCat_ai,
	setFAdmCat_si,
	setFAdmCat_en,
	setFAdmCat_de,
} from '../../../globalstate';

// import { defaultQueryFn } from '../../../queryapi';

import { Getter } from '../../../generaltools';

function CategoryItem_ctrl(forCtrler) {

	const handleClickOnCategoryitem = () => {
		// var link = "/category/" + forCtrler.category_item.id;

		globalStore.dispatch(setCurrentItem({item: {id: 0}}));
		globalStore.dispatch(setCurrentCategory({category: forCtrler.category_item}));
		globalStore.dispatch(setCurrentSection({section: Getter.getSection(forCtrler.category_item.section_id, forCtrler.allSection)}));

		const routeCategory = '/category/' + forCtrler.category_item.id;

		forCtrler.navigate(routeCategory);
	};

	const setCategoryName = (initialName) => {
		if (forCtrler.location === 'MENULEFT') {
			if (initialName.length > 8) {
				return initialName.substring(0, 7) + '.';
			}
		} else {
			if (initialName.length > 7) {
				return initialName.substring(0, 6) + '.';
			}
		}
		return initialName;
	};

	const isCurrentCategory = () => {
		let idCurrCate = null;
		if (forCtrler.currentCategory) {
			idCurrCate = forCtrler.currentCategory.id;
		} else {
			idCurrCate = forCtrler.idCate;
		}

		if (forCtrler.category_item && idCurrCate) {
			if (forCtrler.category_item.id.toString() === idCurrCate.toString()) {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	};

	const isAdminCurrentCategory = () => {
		if (forCtrler.category_item.id === forCtrler.adminCurrentCategory.id) {
			return true;
		} else {
			return false;
		}
	};

	const onClickAdminCategory = () => {
		globalStore.dispatch(setAdminCurrCategory(forCtrler.category_item));
		globalStore.dispatch(setAdminIsActive("CATEGORY"));
		globalStore.dispatch(setAdminModeAddMod("M"));

		globalStore.dispatch(setFAdmCat_na(forCtrler.category_item.name));
		globalStore.dispatch(setFAdmCat_or(forCtrler.category_item.order));
		globalStore.dispatch(setFAdmCat_ip(forCtrler.category_item.img_path));
		globalStore.dispatch(setFAdmCat_ai(forCtrler.category_item.auto_img));
		globalStore.dispatch(setFAdmCat_si(forCtrler.category_item.section_id));
		globalStore.dispatch(setFAdmCat_en(forCtrler.category_item.enable));
		globalStore.dispatch(setFAdmCat_de(forCtrler.category_item.is_delete));
	};

	return {
		handleClickOnCategoryitem: handleClickOnCategoryitem,
		setCategoryName: setCategoryName,
		isCurrentCategory: isCurrentCategory,
		isAdminCurrentCategory: isAdminCurrentCategory,
		onClickAdminCategory: onClickAdminCategory,
	};
} 

export default CategoryItem_ctrl;