
import { useSelector } from 'react-redux';
import { useState } from 'react';

import { Getter } from '../../../../generaltools';

import {
	globalStore,
	getAdminCurrAbout,
	getFAdmAbo_ti,
	getFAdmAbo_tf,
	getFAdmAbo_co,
	getFAdmAbo_ip,
	getFAdmAbo_op,
	getFAdmAbo_en,
	getFAdmAbo_de,
	getAboutParagraphs,
	setFAdmAbo_op,
} from '../../../../globalstate';

import { AdminAddManageAbout_ctrl } from '../../../../controler/';

import {
	AdminAddAbout,
	AdminManageAbout,
} from './';

function AdminAddManageAbout({addManage}) {

	const aboutPara_all = useSelector(getAboutParagraphs(globalStore.getState())) || [];
	const maxOrdreP1 = Getter.getMaxAboutOrderP1(aboutPara_all);

	const aboutPara_old = useSelector(getAdminCurrAbout(globalStore.getState())) || {};

	const aboutPara_ti = useSelector(getFAdmAbo_ti(globalStore.getState())) || "";
	const aboutPara_tf = useSelector(getFAdmAbo_tf(globalStore.getState())) || 0;
	const aboutPara_co = useSelector(getFAdmAbo_co(globalStore.getState())) || "";
	const aboutPara_ip = useSelector(getFAdmAbo_ip(globalStore.getState())) || "";
	const aboutPara_op = useSelector(getFAdmAbo_op(globalStore.getState())) || 0;
	const aboutPara_en = useSelector(getFAdmAbo_en(globalStore.getState()));
	const aboutPara_de = useSelector(getFAdmAbo_de(globalStore.getState()));

	if (aboutPara_op === 0) {
		globalStore.dispatch(setFAdmAbo_op(maxOrdreP1));
	}

	const [errorEpty_all, setErrorEpty_all] = useState(false);
	const [errorEpty_ti, setErrorEpty_ti] = useState(false);
	const [errorEpty_tf, setErrorEpty_tf] = useState(false);
	const [errorEpty_op, setErrorEpty_op] = useState(false);

	const forCtrler = {
		aboutPara_old: aboutPara_old,
		aboutPara_ti: aboutPara_ti,
		aboutPara_tf: aboutPara_tf,
		aboutPara_co: aboutPara_co,
		aboutPara_ip: aboutPara_ip,
		aboutPara_op: aboutPara_op,
		aboutPara_en: aboutPara_en,
		aboutPara_de: aboutPara_de,
		errorEpty_all: errorEpty_all,
		errorEpty_ti: errorEpty_ti,
		errorEpty_tf: errorEpty_tf,
		errorEpty_op: errorEpty_op,
		setErrorEpty_all: setErrorEpty_all,
		setErrorEpty_ti: setErrorEpty_ti,
		setErrorEpty_tf: setErrorEpty_tf,
		setErrorEpty_op: setErrorEpty_op,
		maxOrdreP1: maxOrdreP1,
	};
	const ctrler = AdminAddManageAbout_ctrl(forCtrler);

	return (
		<div>
			{ addManage === 'A' ? (
					<AdminAddAbout
						onCLickSave={ctrler.handleSaveAboutParaAdd}
						ctrler={ctrler} />
				) : (
					<AdminManageAbout
						onCLickSave={ctrler.handleSaveAboutParaMod}
						ctrler={ctrler} />
				)
			}
		</div>
	);
} 

export default AdminAddManageAbout;


					// <AdminManageAbout
					// 	onCLickSave={ctrler.handleSaveAboutParaMod}
					// 	ctrler={ctrler}
					// 	aboutParaCurr={aboutPara_old} />