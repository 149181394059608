
import { BASE_URL_SIMPLE } from '../globalvar.js';
import SetHeader from './setheader.js';

import defaultImg from '../img/tools/default_img.jpeg';
import imgLogo from '../img/tools/logo.jpeg';

export default class Getter {

	static getSection = (sectionId, allSection) => {
		let retSection = {id: 0};
		allSection.forEach((section) => {
			if (section.id === sectionId) {
				retSection = section;
			}
		});
		return retSection;
	};

	static getCategory = (categoryId, allCategories) => {
		let retCategory = {id: 0};
		allCategories.forEach((category) => {
			if (category.id === categoryId) {
				retCategory = category;
			}
		});
		return retCategory;
		// // A rechercher via l'api ou directement dans la liste des section du globalstate
		// return {
		// 	id: 0,
		// 	section_id: 0,
		// 	name: 'toto',
		// 	img_path: 'toto.png',
		// };
	};

	static getImgPath = (imgPath) => {
		if (imgPath) {
			return BASE_URL_SIMPLE.concat(imgPath);
		} else {
			return defaultImg;
		}
	};

	static getMaxAboutOrderP1 = (aboutAll) => {
		let maxOrder = 0;

		aboutAll.forEach((aboutPara) => {
			if (aboutPara.order_place > maxOrder) {
				maxOrder = aboutPara.order_place;
			}
		})

		return ++maxOrder;
	};

	static getMaxSectionOrderP1 = (sectionAll) => {
		let maxOrder = 0;

		sectionAll.forEach((section) => {
			if (section.order > maxOrder) {
				maxOrder = section.order;
			}
		})

		return ++maxOrder;
	};

	static getMaxCategoryOrderP1 = (categoryAll, currSect, secId) => {
		let maxOrder = 0;
		const sectionId = secId || currSect.id;

		categoryAll.forEach((category) => {
			if (parseInt(category.section_id) === parseInt(sectionId)) {
				if (category.order > maxOrder) {
					maxOrder = category.order;
				}
			}
		})

		return ++maxOrder;
	};

	static getMaxItemOrderP1 = (itemAll, currCate, catId) => {
		let maxOrder = 0;
		const categoryId = catId || currCate.id;

		itemAll.forEach((item) => {
			if (parseInt(item.category_id) === parseInt(categoryId)) {
				if (item.order > maxOrder) {
					maxOrder = item.order;
				}
			}
		})

		return ++maxOrder;
	};

	static getMaxItemCarouOrderP1 = (itemAll) => {
		let maxOrder = 0;

		itemAll.forEach((item) => {
			if (item.carousel_order > maxOrder) {
				maxOrder = item.carousel_order;
			}
		})

		return ++maxOrder;
	};

	static getHeaderPage = ({pageName, info, actuPara, aboutParas, sections, sectionId, categories, categoryId, items, itemId}) => {

		switch(pageName) {
			case 'WELCOME':
				// used param : info
				return SetHeader.getHeaderPageWelcome(info);
			
			case 'CONTACT':
				// used param : info
				return SetHeader.getHeaderPageContact(info);
			
			case 'ABOUT':
				// used param : aboutParas
				return SetHeader.getHeaderPageAbout(aboutParas);
			
			case 'CATEGORY':
				// used param : sections, sectionId, categories, categoryId, items
				return SetHeader.getHeaderPageCategory(sections, sectionId, categories, categoryId, items);
			
			case 'ITEM':
				// used param : categories, categoryId, items, itemId
				return SetHeader.getHeaderPageItem(categories, categoryId, items, itemId);

			case 'ACTU':
				// used param : actuPara
				return SetHeader.getHeaderPageActu(actuPara);

			case 'SITEPLAN':
				// used param : no param
				return SetHeader.getHeaderPagePlan();

			default:
				// used param : no param
				return {
					title: "Envie d'un tattoo dark ? Je vous propose mon art",
					descr: "Des tatouages pour tout le monde ! Je vous partage mes créations et ma passion pour mon métier de tatoueuse.",
					img: imgLogo,
				};
		}
	};

	static getDateFormat = (dt, input) => {
		if (dt) {
			switch(input) {
				case true:
					let dt_input = dt.substring(6, 10).toString().concat(dt.substring(3, 5).toString()).concat(dt.substring(0, 2).toString());
					return parseInt(dt_input);

				case false:
					let dt_output = dt;
					return dt_output;

				default:
					return dt;
			}
		} else {
			return dt;
		}
	};

	static menuDisplayActu = (actuPara) => {
		let dtJour = new Date();
		let day = dtJour.getDate();
		let month = dtJour.getMonth() + 1;
		if (day < 10) {
			day = "0" + day;
		}
		if (month < 10) {
			month = "0" + month;
		}

		let dt_jour = "" + dtJour.getFullYear() + month + day;
		if (actuPara !== {} &&
			this.getDateFormat(actuPara.dt_start, true) <= parseInt(dt_jour) &&
			this.getDateFormat(actuPara.dt_end, true) >= parseInt(dt_jour)) {
			return true;
		}

		return false;
	};
};
