
import { useSelector } from 'react-redux';
import { useState } from 'react';

import { Getter } from '../../../../generaltools';

import {
	globalStore,
	getAdminCurrCategory,
	getFAdmCat_na,
	getFAdmCat_or,
	getFAdmCat_ip,
	getFAdmCat_ai,
	getFAdmCat_si,
	getFAdmCat_en,
	getFAdmCat_de,
	getAllCategory,
	getAdminCurrSection,
	setFAdmCat_or,
} from '../../../../globalstate';

import { AdminAddManageCategory_ctrl } from '../../../../controler/';

import {
	AdminAddCategory,
	AdminManageCategory,
} from './';

function AdminAddManageCategory({addManage, sectionList}) {

	const category_old = useSelector(getAdminCurrCategory(globalStore.getState())) || {};

	const category_na = useSelector(getFAdmCat_na(globalStore.getState())) || "";
	const category_or = useSelector(getFAdmCat_or(globalStore.getState())) || "";
	const category_ip = useSelector(getFAdmCat_ip(globalStore.getState())) || "";
	const category_ai = useSelector(getFAdmCat_ai(globalStore.getState()));
	const category_si = useSelector(getFAdmCat_si(globalStore.getState())) || 0;
	const category_en = useSelector(getFAdmCat_en(globalStore.getState()));
	const category_de = useSelector(getFAdmCat_de(globalStore.getState()));

	const currSection = useSelector(getAdminCurrSection(globalStore.getState())) || {};
	const category_all = useSelector(getAllCategory(globalStore.getState())) || [];
	const maxOrdreP1 = Getter.getMaxCategoryOrderP1(category_all, currSection, category_si);

	if (category_or === 0) {
		globalStore.dispatch(setFAdmCat_or(maxOrdreP1));
	}

	const [errorEpty_all, setErrorEpty_all] = useState(false);
	const [errorEpty_na, setErrorEpty_na] = useState(false);
	const [errorEpty_or, setErrorEpty_or] = useState(false);
	const [errorEpty_si, setErrorEpty_si] = useState(false);

	const [catDisplay_ip, setCatDisplay_ip] = useState(!category_ai);


	const forCtrler = {
		category_old: category_old,
		category_na: category_na,
		category_or: category_or,
		category_ip: category_ip,
		category_ai: category_ai,
		category_si: category_si,
		category_en: category_en,
		category_de: category_de,
		errorEpty_all: errorEpty_all,
		errorEpty_na: errorEpty_na,
		errorEpty_or: errorEpty_or,
		errorEpty_si: errorEpty_si,
		setErrorEpty_all: setErrorEpty_all,
		setErrorEpty_na: setErrorEpty_na,
		setErrorEpty_or: setErrorEpty_or,
		setErrorEpty_si: setErrorEpty_si,
		maxOrdreP1: maxOrdreP1,
		catDisplay_ip: catDisplay_ip,
		setCatDisplay_ip: setCatDisplay_ip,
	};
	const ctrler = AdminAddManageCategory_ctrl(forCtrler);

	return (
		<div>
			{ addManage === 'A' ? (
					<AdminAddCategory
						onCLickSave={ctrler.handleSaveCategoryAdd}
						ctrler={ctrler}
						sectionList={sectionList} />
				) : (
					<AdminManageCategory
						onCLickSave={ctrler.handleSaveCategoryMod}
						ctrler={ctrler}
						sectionList={sectionList} />
				)
			}
		</div>
	);
} 

export default AdminAddManageCategory;