
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
	globalStore,
	getActuPara,
} from '../../../globalstate';

import { Getter } from '../../../generaltools';

import { MenuHeader_ctrl } from '../../../controler/';

import {
	StyMenuHeaderPlaceDiv,
	StyMenuHeaderItemSpan,
	StyMenuHeaderItemImpSpan,
} from '../../../style/';

function MenuHeader() {

	const actuPara = useSelector(getActuPara(globalStore.getState())) || {};

	const navigate = useNavigate();

	const forCtrler = {
		navigate: navigate,
	};
	const ctrler = MenuHeader_ctrl(forCtrler);

	return (
		<StyMenuHeaderPlaceDiv>
			<div>
				{
					Getter.menuDisplayActu(actuPara) === true ? (
						<StyMenuHeaderItemImpSpan onClick={ctrler.hamdleClickOnActu}>Actu</StyMenuHeaderItemImpSpan>
					) : (<span></span>)
				}
				<StyMenuHeaderItemSpan onClick={ctrler.hamdleClickOnWelcome}>Accueil</StyMenuHeaderItemSpan>
			</div>
			<StyMenuHeaderItemSpan onClick={ctrler.hamdleClickOnAbout}>À propos</StyMenuHeaderItemSpan>
			<StyMenuHeaderItemSpan onClick={ctrler.hamdleClickOnContact}>Contact</StyMenuHeaderItemSpan>
		</StyMenuHeaderPlaceDiv>
	);
} 

export default MenuHeader;
