
import { defaultQueryFn } from '../../../../queryapi';

import {
	globalStore,
	setFAdmAbo_ti,
	setFAdmAbo_tf,
	setFAdmAbo_co,
	// setFAdmAbo_ip,
	setFAdmAbo_op,
	setFAdmAbo_en,
	setFAdmAbo_de,
	setAboutParagraphs,
} from '../../../../globalstate';

function AdminAddManageAbout_ctrl(forCtrler) {

	const checkErrorGeneral = (fromBtSave) => {
		let errorAll = false;

		if (forCtrler.errorEpty_ti === true || forCtrler.aboutPara_ti === "") {
			if (forCtrler.aboutPara_ti === "" && fromBtSave) {
				forCtrler.setErrorEpty_ti(true);
			}
			errorAll = true;
		}
		
		if (forCtrler.errorEpty_tf === true || forCtrler.aboutPara_tf === "0") {
			if (forCtrler.aboutPara_tf === "0" && fromBtSave) {
				forCtrler.setErrorEpty_tf(true);
			}
			errorAll = true;
		}
		
		if (forCtrler.errorEpty_op === true || forCtrler.aboutPara_op === "0") {
			if (forCtrler.aboutPara_op === "0" && fromBtSave) {
				forCtrler.setErrorEpty_op(true);
			}
			errorAll = true;
		}

		forCtrler.setErrorEpty_all(errorAll);
		forCtrler.errorEpty_all = errorAll;
	};

	const handleSaveAboutParaAdd = async (event) => {
		event.preventDefault();

		checkErrorGeneral(true);

		if (forCtrler.errorEpty_all === false) {
			await defaultQueryFn({
				query: "about_paragraphs",
				typeReq: "POST",
				args: {
					title: forCtrler.aboutPara_ti,
					title_force: forCtrler.aboutPara_tf,
					content: forCtrler.aboutPara_co,
					img_path: forCtrler.aboutPara_ip.signed_id, // blob: forCtrler.avatarFile.signed_id
					order_place: forCtrler.aboutPara_op,
					enable: forCtrler.aboutPara_en,
				},
				enable: true,
				special: null
			})
				.then( async (result) => {
					globalStore.dispatch(setAboutParagraphs(
						result && result.data && result.data.result ? result.data.result : null
					));

					return result;
				})
				.catch((error) => {
					return error;
				})
		}
	};

	const handleSaveAboutParaMod = async (event) => {
		event.preventDefault();

		checkErrorGeneral(true);

		if (forCtrler.errorEpty_all === false) {
			const query = "about_paragraphs/" + forCtrler.aboutPara_old.id;

			if (forCtrler.aboutPara_de === true) {
				await defaultQueryFn({
					query: query,
					typeReq: "DELETE",
					args: {},
					enable: true,
					special: null
				})
					.then( async (result) => {
						globalStore.dispatch(setAboutParagraphs(
							result && result.data && result.data.result ? result.data.result : null
						));

						return result;
					})
					.catch((error) => {
						return error;
					})
			} else {
				await defaultQueryFn({
					query: query,
					typeReq: "PUT",
					args: {
						title: forCtrler.aboutPara_ti,
						title_force: forCtrler.aboutPara_tf,
						content: forCtrler.aboutPara_co,
						img_path: forCtrler.aboutPara_ip.signed_id,
						order_place: forCtrler.aboutPara_op,
						enable: forCtrler.aboutPara_en,
					},
					enable: true,
					special: null
				})
					.then( async (result) => {
						globalStore.dispatch(setAboutParagraphs(
							result && result.data && result.data.result ? result.data.result : null
						));

						return result;
					})
					.catch((error) => {
						return error;
					})
			}
		}
	};

	const handleChangeAboutPara_ti = (event) => {
		// forCtrler.stateAddManAbout.setAboutParaNew_ti(event.target.value);
		if (event.target.value === "") {
			forCtrler.setErrorEpty_ti(true);
		} else {
			forCtrler.setErrorEpty_ti(false);
		}
		checkErrorGeneral(false);
		globalStore.dispatch(setFAdmAbo_ti(event.target.value));
	};

	const handleChangeAboutPara_tf = (event) => {
		// forCtrler.stateAddManAbout.setAboutParaNew_tf(event.target.value);
		if (event.target.value === "0") {
			forCtrler.setErrorEpty_tf(true);
		} else {
			forCtrler.setErrorEpty_tf(false);
		}
		checkErrorGeneral(false);
		globalStore.dispatch(setFAdmAbo_tf(event.target.value));
	};

	const handleChangeAboutPara_co = (event) => {
		// forCtrler.stateAddManAbout.setAboutParaNew_co(event.target.value);
		globalStore.dispatch(setFAdmAbo_co(event.target.value));
	};

	const handleChangeAboutPara_ip = async (event) => {
		await defaultQueryFn({
				query: "NONE",
				typeReq: "FILE",
				args: event.target.files[0],
				enable: true,
				special: { typeItem: 'ABOUT' }
			})
				.then((result) => {
					return result;
				})
				.catch((error) => {
					return error;
				})
		// globalStore.dispatch(setFAdmAbo_ip(event.target.value));
	};

	const handleChangeAboutPara_op = (event) => {
		// forCtrler.stateAddManAbout.setAboutParaNew_op(event.target.value);
		if (event.target.value === "") {
			forCtrler.setErrorEpty_op(true);
		} else {
			forCtrler.setErrorEpty_op(false);
		}
		checkErrorGeneral(false);
		globalStore.dispatch(setFAdmAbo_op(event.target.value));
	};

	const handleChangeAboutPara_en = (event) => {
		if (event.target.value === 'N') {
			// forCtrler.stateAddManAbout.setAboutParaNew_en(false);
			globalStore.dispatch(setFAdmAbo_en(false));
		} else {
			// forCtrler.stateAddManAbout.setAboutParaNew_en(true);
			globalStore.dispatch(setFAdmAbo_en(true));
		}
	};

	const handleChangeAboutPara_de = (event) => {
		// forCtrler.stateAddManAbout.setAboutParaNew_de(event.target.checked);
		if (event.target.checked === true) {
			alert("Attention ! Vous venez de cocher la suppression, la sauvegarde supprimera ce paragraphe");
		}
		globalStore.dispatch(setFAdmAbo_de(event.target.checked));
	};

	const putAboutOrder = () => {
		let result = [];
		for (let i = 1; i <= forCtrler.maxOrdreP1; i++) {
			result.push(i);
		}
		return result;
	};

	// const aboutPara = {
	// 	title: forCtrler.stateAddManAbout.aboutParaNew_ti,
	// 	title_force: forCtrler.stateAddManAbout.aboutParaNew_tf,
	// 	content: forCtrler.stateAddManAbout.aboutParaNew_co,
	// 	img_path: forCtrler.stateAddManAbout.aboutParaNew_ip,
	// 	order_place: forCtrler.stateAddManAbout.aboutParaNew_op,
	// 	enable: forCtrler.stateAddManAbout.aboutParaNew_en,
	// 	is_delete: forCtrler.stateAddManAbout.aboutParaNew_de,
	// };	

	return {
		handleSaveAboutParaAdd: handleSaveAboutParaAdd,
		handleSaveAboutParaMod: handleSaveAboutParaMod,
		handleChangeAboutPara_ti: handleChangeAboutPara_ti,
		handleChangeAboutPara_tf: handleChangeAboutPara_tf,
		handleChangeAboutPara_co: handleChangeAboutPara_co,
		handleChangeAboutPara_ip: handleChangeAboutPara_ip,
		handleChangeAboutPara_op: handleChangeAboutPara_op,
		handleChangeAboutPara_en: handleChangeAboutPara_en,
		handleChangeAboutPara_de: handleChangeAboutPara_de,
		errorEpty_all: forCtrler.errorEpty_all,
		errorEpty_ti: forCtrler.errorEpty_ti,
		errorEpty_tf: forCtrler.errorEpty_tf,
		errorEpty_op: forCtrler.errorEpty_op,
		maxOrdreP1: forCtrler.maxOrdreP1,
		putAboutOrder: putAboutOrder,
		// aboutPara: aboutPara,
	};
} 

export default AdminAddManageAbout_ctrl;