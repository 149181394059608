
import {
	globalStore,
	setCurrentItem,
	setCurrentCategory,
	setCurrentSection,
} from '../../../globalstate';

import { Getter } from '../../../generaltools';

function Item_ctrl(forCtrler) {

	const getItem = () => {
		let retItem = forCtrler.initItem;
		forCtrler.elementsTree.items.forEach((item) => {
			if (item.id.toString() === forCtrler.idItem.toString()) {
				const category = Getter.getCategory(item.category_id, forCtrler.elementsTree.categories);

				globalStore.dispatch(setCurrentItem(item));
				globalStore.dispatch(setCurrentCategory({category: category}));
				globalStore.dispatch(setCurrentSection({section: Getter.getSection(category.section_id, forCtrler.elementsTree.sections)}));

				retItem = item;
			}
		});
		return retItem;
	};

	return {
		getItem: getItem,
	};
} 

export default Item_ctrl;