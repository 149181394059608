
import styled from 'styled-components';


const StyAboutPlaceDiv = styled.div`
	margin-left: 5%;
	width: 90%;
`

const StyAboutContentParaPlaceDiv = styled.article`
`

const StyAboutContentParaContentDiv = styled.div`
`

const StyAboutContentParaContentImgDiv = styled.div`
	text-align: center;
	margin-bottom: 10px;
`

const StyAboutContentParaContentImgImg = styled.img`
	border: 1px solid rgb(215, 216, 221);
	border-radius: 10px 10px 10px 10px;
	width: 90%;
	max-width: 700px;
`

const StyAboutContentParaContentTextP = styled.main`
	font-size: 15px;
	font-weight: 400;
	text-justify: auto;
`

const StyAboutTitleForceH2 = styled.h2`
	border-top: 1px solid rgb(0, 158, 199);
	border-radius: 10px 10px 0px 0px;
	font-size: 22px;
	font-weight: 900;
	padding-top: 5px;
	text-align: center;
	margin-top: 70px;
	${props => props.location === 'ADMIN' ? '&:hover{cursor: pointer;}' : ''};
`

const StyAboutTitleForceH3 = styled.h3`
	font-size: 20px;
	font-weight: 900;
	text-decoration: underline;
	${props => props.location === 'ADMIN' ? '&:hover{cursor: pointer;}' : ''};
`

const StyAboutTitleForceH4 = styled.h4`
	font-size: 20px;
	font-weight: 900;
	font-style: italic;
	${props => props.location === 'ADMIN' ? '&:hover{cursor: pointer;}' : ''};
`

const StyAboutTitleForceH5 = styled.h5`
	font-size: 18px;
	font-weight: 900;
	text-decoration: underline;
	${props => props.location === 'ADMIN' ? '&:hover{cursor: pointer;}' : ''};
`

const StyAboutTitleForceH6 = styled.h6`
	font-size: 18px;
	font-weight: 900;
	font-style: italic;
	${props => props.location === 'ADMIN' ? '&:hover{cursor: pointer;}' : ''};
`

const StyAboutTitleForceH7 = styled.h6`
	font-size: 15px;
	font-weight: 900;
	text-decoration: underline;
	${props => props.location === 'ADMIN' ? '&:hover{cursor: pointer;}' : ''};
`

const StyAboutPageHeader = styled.div`
	font-size: 14px;
	font-weight: 400;
	text-align: center;
`

const StyAboutPageH1 = styled.h1`
	font-size: 24px;
	font-weight: 900;
`




export {
	StyAboutPlaceDiv,
	StyAboutContentParaPlaceDiv,
	StyAboutContentParaContentDiv,
	StyAboutContentParaContentImgDiv,
	StyAboutContentParaContentImgImg,
	StyAboutContentParaContentTextP,
	StyAboutTitleForceH2,
	StyAboutTitleForceH3,
	StyAboutTitleForceH4,
	StyAboutTitleForceH5,
	StyAboutTitleForceH6,
	StyAboutTitleForceH7,
	StyAboutPageHeader,
	StyAboutPageH1,
};