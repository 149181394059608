
import { createSlice, configureStore } from '@reduxjs/toolkit';

const counterSlice = createSlice({
	name: 'mainState',
	initialState: {
		admConnected: false,

		currCategory: {},
		currSection: {},
		currItem: {},

		aboutParagraphs: [],
		adminInfos: {},
		actuPara: {},

		allSection: [],
		allCategory: [],
		allItem: [],

		adminCurrSection: {},
		adminCurrCategory: {},
		adminCurrItem: {},
		adminCurrAbout: {},
		adminIsActive: "",
		adminPartActive: "",
		adminModeAddMod: "",

		fAdmInf_linkIg: "",
		fAdmInf_linkFb: "",
		fAdmInf_linkTt: "",
		fAdmInf_linkPt: "",
		fAdmInf_myEmail: "",
		fAdmInf_shopName: "",
		fAdmInf_shopSt1: "",
		fAdmInf_shopSt2: "",
		fAdmInf_shopCp: "",
		fAdmInf_shopCt: "",
		fAdmInf_shopCo: "",
		fAdmInf_shopPh: "",
		fAdmInf_shopMa: "",
		fAdmInf_shopSi: "",

		fActuPara_ti: "",
		fActuPara_dc: "",
		fActuPara_ip: "",
		fActuPara_ds: "",
		fActuPara_de: "",
		fActuPara_sa: true,
		fActuPara_ad: "",
		fActuPara_cp: "",
		fActuPara_ci: "",

		fAdmAbo_ti: "",
		fAdmAbo_tf: "",
		fAdmAbo_co: "",
		fAdmAbo_ip: "",
		fAdmAbo_op: 0,
		fAdmAbo_en: true,
		fAdmAbo_de: false,

		fAdmSec_na: "",
		fAdmSec_or: "",
		fAdmSec_en: true,
		fAdmSec_de: false,

		fAdmCat_na: "",
		fAdmCat_or: 0,
		fAdmCat_ip: "",
		fAdmCat_ai: true,
		fAdmCat_si: 0,
		fAdmCat_en: true,
		fAdmCat_de: false,

		fAdmIte_ti: "",
		fAdmIte_ds: "",
		fAdmIte_ta: "",
		fAdmIte_co: 0,
		fAdmIte_or: 0,
		fAdmIte_ip: "",
		fAdmIte_ci: 0,
		fAdmIte_av: true,
		fAdmIte_en: true,
		fAdmIte_de: false,
	},
	reducers: {
		setAdmConnected: (state, data) => {
			const { admConnected } = data.payload;
			state.admConnected = admConnected;
		},
		setCurrentCategory: (state, data) => {
			const { category } = data.payload;
			state.currCategory = category;
		},
		setCurrentSection: (state, data) => {
			const { section } = data.payload;
			state.currSection = section;
		},
		setCurrentItem: (state, data) => {
			const { item } = data.payload;
			state.currItem = item;
		},

		setAboutParagraphs: (state, data) => {
			const aboutParagraphs = data.payload;
			state.aboutParagraphs = aboutParagraphs;
		},
		setAdminInfos: (state, data) => {
			const adminInfos = data.payload;
			state.adminInfos = adminInfos;
		},
		setActuPara: (state, data) => {
			const actuPara = data.payload;
			state.actuPara = actuPara;
		},

		setAllSection: (state, data) => {
			const allSection = data.payload;
			state.allSection = allSection;
		},
		setAllCategory: (state, data) => {
			const allCategory = data.payload;
			state.allCategory = allCategory;
		},
		setAllItem: (state, data) => {
			const allItem = data.payload;
			state.allItem = allItem;
		},
		
		setAdminCurrSection: (state, data) => {
			const adminCurrSection = data.payload;
			state.adminCurrSection = adminCurrSection;
		},
		setAdminCurrCategory: (state, data) => {
			const adminCurrCategory = data.payload;
			state.adminCurrCategory = adminCurrCategory;
		},
		setAdminCurrItem: (state, data) => {
			const adminCurrItem = data.payload;
			state.adminCurrItem = adminCurrItem;
		},
		setAdminCurrAbout: (state, data) => {
			const adminCurrAbout = data.payload;
			state.adminCurrAbout = adminCurrAbout;
		},
		setAdminIsActive: (state, data) => {
			const adminIsActive = data.payload;
			state.adminIsActive = adminIsActive;
		},
		setAdminPartActive: (state, data) => {
			const adminPartActive = data.payload;
			state.adminPartActive = adminPartActive;
		},
		setAdminModeAddMod: (state, data) => {
			const adminModeAddMod = data.payload;
			state.adminModeAddMod = adminModeAddMod;
		},

		setFAdmInf_linkIg: (state, data) => {
			const fAdmInf_linkIg = data.payload;
			state.fAdmInf_linkIg = fAdmInf_linkIg;
		},
		setFAdmInf_linkFb: (state, data) => {
			const fAdmInf_linkFb = data.payload;
			state.fAdmInf_linkFb = fAdmInf_linkFb;
		},
		setFAdmInf_linkTt: (state, data) => {
			const fAdmInf_linkTt = data.payload;
			state.fAdmInf_linkTt = fAdmInf_linkTt;
		},
		setFAdmInf_linkPt: (state, data) => {
			const fAdmInf_linkPt = data.payload;
			state.fAdmInf_linkPt = fAdmInf_linkPt;
		},
		setFAdmInf_myEmail: (state, data) => {
			const fAdmInf_myEmail = data.payload;
			state.fAdmInf_myEmail = fAdmInf_myEmail;
		},
		setFAdmInf_shopName: (state, data) => {
			const fAdmInf_shopName = data.payload;
			state.fAdmInf_shopName = fAdmInf_shopName;
		},
		setFAdmInf_shopSt1: (state, data) => {
			const fAdmInf_shopSt1 = data.payload;
			state.fAdmInf_shopSt1 = fAdmInf_shopSt1;
		},
		setFAdmInf_shopSt2: (state, data) => {
			const fAdmInf_shopSt2 = data.payload;
			state.fAdmInf_shopSt2 = fAdmInf_shopSt2;
		},
		setFAdmInf_shopCp: (state, data) => {
			const fAdmInf_shopCp = data.payload;
			state.fAdmInf_shopCp = fAdmInf_shopCp;
		},
		setFAdmInf_shopCt: (state, data) => {
			const fAdmInf_shopCt = data.payload;
			state.fAdmInf_shopCt = fAdmInf_shopCt;
		},
		setFAdmInf_shopCo: (state, data) => {
			const fAdmInf_shopCo = data.payload;
			state.fAdmInf_shopCo = fAdmInf_shopCo;
		},
		setFAdmInf_shopPh: (state, data) => {
			const fAdmInf_shopPh = data.payload;
			state.fAdmInf_shopPh = fAdmInf_shopPh;
		},
		setFAdmInf_shopMa: (state, data) => {
			const fAdmInf_shopMa = data.payload;
			state.fAdmInf_shopMa = fAdmInf_shopMa;
		},
		setFAdmInf_shopSi: (state, data) => {
			const fAdmInf_shopSi = data.payload;
			state.fAdmInf_shopSi = fAdmInf_shopSi;
		},

		setFActuPara_ti: (state, data) => {
			const fActuPara_ti = data.payload;
			state.fActuPara_ti = fActuPara_ti;
		},
		setFActuPara_dc: (state, data) => {
			const fActuPara_dc = data.payload;
			state.fActuPara_dc = fActuPara_dc;
		},
		setFActuPara_ip: (state, data) => {
			const fActuPara_ip = data.payload;
			state.fActuPara_ip = fActuPara_ip;
		},
		setFActuPara_ds: (state, data) => {
			const fActuPara_ds = data.payload;
			state.fActuPara_ds = fActuPara_ds;
		},
		setFActuPara_de: (state, data) => {
			const fActuPara_de = data.payload;
			state.fActuPara_de = fActuPara_de;
		},
		setFActuPara_sa: (state, data) => {
			const fActuPara_sa = data.payload;
			state.fActuPara_sa = fActuPara_sa;
		},
		setFActuPara_ad: (state, data) => {
			const fActuPara_ad = data.payload;
			state.fActuPara_ad = fActuPara_ad;
		},
		setFActuPara_cp: (state, data) => {
			const fActuPara_cp = data.payload;
			state.fActuPara_cp = fActuPara_cp;
		},
		setFActuPara_ci: (state, data) => {
			const fActuPara_ci = data.payload;
			state.fActuPara_ci = fActuPara_ci;
		},

		setFAdmAbo_ti: (state, data) => {
			const fAdmAbo_ti = data.payload;
			state.fAdmAbo_ti = fAdmAbo_ti;
		},
		setFAdmAbo_tf: (state, data) => {
			const fAdmAbo_tf = data.payload;
			state.fAdmAbo_tf = fAdmAbo_tf;
		},
		setFAdmAbo_co: (state, data) => {
			const fAdmAbo_co = data.payload;
			state.fAdmAbo_co = fAdmAbo_co;
		},
		setFAdmAbo_ip: (state, data) => {
			const fAdmAbo_ip = data.payload;
			state.fAdmAbo_ip = fAdmAbo_ip;
		},
		setFAdmAbo_op: (state, data) => {
			const fAdmAbo_op = data.payload;
			state.fAdmAbo_op = fAdmAbo_op;
		},
		setFAdmAbo_en: (state, data) => {
			const fAdmAbo_en = data.payload;
			state.fAdmAbo_en = fAdmAbo_en;
		},
		setFAdmAbo_de: (state, data) => {
			const fAdmAbo_de = data.payload;
			state.fAdmAbo_de = fAdmAbo_de;
		},

		setFAdmSec_na: (state, data) => {
			const fAdmSec_na = data.payload;
			state.fAdmSec_na = fAdmSec_na;
		},
		setFAdmSec_or: (state, data) => {
			const fAdmSec_or = data.payload;
			state.fAdmSec_or = fAdmSec_or;
		},
		setFAdmSec_en: (state, data) => {
			const fAdmSec_en = data.payload;
			state.fAdmSec_en = fAdmSec_en;
		},
		setFAdmSec_de: (state, data) => {
			const fAdmSec_de = data.payload;
			state.fAdmSec_de = fAdmSec_de;
		},

		setFAdmCat_na: (state, data) => {
			const fAdmCat_na = data.payload;
			state.fAdmCat_na = fAdmCat_na;
		},
		setFAdmCat_or: (state, data) => {
			const fAdmCat_or = data.payload;
			state.fAdmCat_or = fAdmCat_or;
		},
		setFAdmCat_ip: (state, data) => {
			const fAdmCat_ip = data.payload;
			state.fAdmCat_ip = fAdmCat_ip;
		},
		setFAdmCat_ai: (state, data) => {
			const fAdmCat_ai = data.payload;
			state.fAdmCat_ai = fAdmCat_ai;
		},
		setFAdmCat_si: (state, data) => {
			const fAdmCat_si = data.payload;
			state.fAdmCat_si = fAdmCat_si;
		},
		setFAdmCat_en: (state, data) => {
			const fAdmCat_en = data.payload;
			state.fAdmCat_en = fAdmCat_en;
		},
		setFAdmCat_de: (state, data) => {
			const fAdmCat_de = data.payload;
			state.fAdmCat_de = fAdmCat_de;
		},

		setFAdmIte_ti: (state, data) => {
			const fAdmIte_ti = data.payload;
			state.fAdmIte_ti = fAdmIte_ti;
		},
		setFAdmIte_ds: (state, data) => {
			const fAdmIte_ds = data.payload;
			state.fAdmIte_ds = fAdmIte_ds;
		},
		setFAdmIte_ta: (state, data) => {
			const fAdmIte_ta = data.payload;
			state.fAdmIte_ta = fAdmIte_ta;
		},
		setFAdmIte_co: (state, data) => {
			const fAdmIte_co = data.payload;
			state.fAdmIte_co = fAdmIte_co;
		},
		setFAdmIte_or: (state, data) => {
			const fAdmIte_or = data.payload;
			state.fAdmIte_or = fAdmIte_or;
		},
		setFAdmIte_ip: (state, data) => {
			const fAdmIte_ip = data.payload;
			state.fAdmIte_ip = fAdmIte_ip;
		},
		setFAdmIte_ci: (state, data) => {
			const fAdmIte_ci = data.payload;
			state.fAdmIte_ci = fAdmIte_ci;
		},
		setFAdmIte_av: (state, data) => {
			const fAdmIte_av = data.payload;
			state.fAdmIte_av = fAdmIte_av;
		},
		setFAdmIte_en: (state, data) => {
			const fAdmIte_en = data.payload;
			state.fAdmIte_en = fAdmIte_en;
		},
		setFAdmIte_de: (state, data) => {
			const fAdmIte_de = data.payload;
			state.fAdmIte_de = fAdmIte_de;
		},
	}
});

const globalStore = configureStore({
	reducer: counterSlice.reducer,
})

const {
	setAdmConnected,

	setCurrentCategory,
	setCurrentSection,
	setCurrentItem,

	setAboutParagraphs,
	setAdminInfos,
	setActuPara,

	setAllSection,
	setAllCategory,
	setAllItem,

	setAdminCurrSection,
	setAdminCurrCategory,
	setAdminCurrItem,
	setAdminCurrAbout,
	setAdminIsActive,
	setAdminPartActive,
	setAdminModeAddMod,

	setFAdmInf_linkIg,
	setFAdmInf_linkFb,
	setFAdmInf_linkTt,
	setFAdmInf_linkPt,
	setFAdmInf_myEmail,
	setFAdmInf_shopName,
	setFAdmInf_shopSt1,
	setFAdmInf_shopSt2,
	setFAdmInf_shopCp,
	setFAdmInf_shopCt,
	setFAdmInf_shopCo,
	setFAdmInf_shopPh,
	setFAdmInf_shopMa,
	setFAdmInf_shopSi,

	setFActuPara_ti,
	setFActuPara_dc,
	setFActuPara_ip,
	setFActuPara_ds,
	setFActuPara_de,
	setFActuPara_sa,
	setFActuPara_ad,
	setFActuPara_cp,
	setFActuPara_ci,

	setFAdmAbo_ti,
	setFAdmAbo_tf,
	setFAdmAbo_co,
	setFAdmAbo_ip,
	setFAdmAbo_op,
	setFAdmAbo_en,
	setFAdmAbo_de,

	setFAdmSec_na,
	setFAdmSec_or,
	setFAdmSec_en,
	setFAdmSec_de,

	setFAdmCat_na,
	setFAdmCat_or,
	setFAdmCat_ip,
	setFAdmCat_ai,
	setFAdmCat_si,
	setFAdmCat_en,
	setFAdmCat_de,

	setFAdmIte_ti,
	setFAdmIte_ds,
	setFAdmIte_ta,
	setFAdmIte_co,
	setFAdmIte_or,
	setFAdmIte_ip,
	setFAdmIte_ci,
	setFAdmIte_av,
	setFAdmIte_en,
	setFAdmIte_de,
} = counterSlice.actions;

const reducers = counterSlice.reducer;

export {
	globalStore,
	reducers,

	setAdmConnected,

	setCurrentCategory,
	setCurrentSection,
	setCurrentItem,

	setAboutParagraphs,
	setAdminInfos,
	setActuPara,

	setAllSection,
	setAllCategory,
	setAllItem,

	setAdminCurrSection,
	setAdminCurrCategory,
	setAdminCurrItem,
	setAdminCurrAbout,
	setAdminIsActive,
	setAdminPartActive,
	setAdminModeAddMod,

	setFAdmInf_linkIg,
	setFAdmInf_linkFb,
	setFAdmInf_linkTt,
	setFAdmInf_linkPt,
	setFAdmInf_myEmail,
	setFAdmInf_shopName,
	setFAdmInf_shopSt1,
	setFAdmInf_shopSt2,
	setFAdmInf_shopCp,
	setFAdmInf_shopCt,
	setFAdmInf_shopCo,
	setFAdmInf_shopPh,
	setFAdmInf_shopMa,
	setFAdmInf_shopSi,

	setFActuPara_ti,
	setFActuPara_dc,
	setFActuPara_ip,
	setFActuPara_ds,
	setFActuPara_de,
	setFActuPara_sa,
	setFActuPara_ad,
	setFActuPara_cp,
	setFActuPara_ci,

	setFAdmAbo_ti,
	setFAdmAbo_tf,
	setFAdmAbo_co,
	setFAdmAbo_ip,
	setFAdmAbo_op,
	setFAdmAbo_en,
	setFAdmAbo_de,

	setFAdmSec_na,
	setFAdmSec_or,
	setFAdmSec_en,
	setFAdmSec_de,

	setFAdmCat_na,
	setFAdmCat_or,
	setFAdmCat_ip,
	setFAdmCat_ai,
	setFAdmCat_si,
	setFAdmCat_en,
	setFAdmCat_de,

	setFAdmIte_ti,
	setFAdmIte_ds,
	setFAdmIte_ta,
	setFAdmIte_co,
	setFAdmIte_or,
	setFAdmIte_ip,
	setFAdmIte_ci,
	setFAdmIte_av,
	setFAdmIte_en,
	setFAdmIte_de,
};
