
import {
	globalStore,
	setCurrentItem,
	setCurrentCategory,
	setCurrentSection,
} from '../../../globalstate';

import { Getter } from '../../../generaltools';

function Category_ctrl(forCtrler) {

	const getCategory = () => {
		let retCate = forCtrler.initCate;
		forCtrler.elementsTree.categories.forEach((category) => {
			if (category.id.toString() === forCtrler.idCate.toString()) {
				globalStore.dispatch(setCurrentItem({item: {id: 0}}));
				globalStore.dispatch(setCurrentCategory(category));
				globalStore.dispatch(setCurrentSection({section: Getter.getSection(category.section_id, forCtrler.elementsTree.sections)}));

				retCate = category;
			}
		});
		return retCate;
	};


	return {
		getCategory: getCategory,
	};
} 

export default Category_ctrl;