
import {
	Section,
} from '../';

import { StyMenuLeftPlaceDiv } from '../../../style/';


function MenuLeft({elementsTree}) {
	return (
		<StyMenuLeftPlaceDiv>
			{
				elementsTree.sections.length !== 0 ? (
					elementsTree.sections.map((sectionListItem, liId) => {
						liId++;
						return (
							<div key={liId}>
								<Section
									location={'MENULEFT'}
									section={sectionListItem}
									categoryList={elementsTree.categories}
									itemList={elementsTree.items} />
							</div>
						)
					})
				) : (<div></div>)
			}
		</StyMenuLeftPlaceDiv>
	);
} 

export default MenuLeft;