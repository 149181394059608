
import { defaultQueryFn } from '../../../queryapi';

import {
	globalStore,
	setAboutParagraphs,
	setAdminCurrAbout,
	setAdminModeAddMod,

	setFAdmAbo_ti,
	setFAdmAbo_tf,
	setFAdmAbo_co,
	setFAdmAbo_ip,
	setFAdmAbo_op,
	setFAdmAbo_en,
	setFAdmAbo_de,
} from '../../../globalstate';

import {
	StyAboutTitleForceH2,
	StyAboutTitleForceH3,
	StyAboutTitleForceH4,
	StyAboutTitleForceH5,
	StyAboutTitleForceH6,
	StyAboutTitleForceH7,
} from '../../../style/';

function About_ctrl(forCtrler) {

	const getAboutParagraph = async () => {
		await defaultQueryFn({
			query: "about_paragraphs",
			typeReq: "GET",
			args: {},
			enable: true,
			special: null
		})
			.then((result) => {
				globalStore.dispatch(setAboutParagraphs(
					result && result.data && result.data.result ? result.data.result : null
				));
				return result;
			})
			.catch((error) => {
				return error;
			})
	};

	const onClickLocalAdmin = (event) => {
	    let para = {};

		forCtrler.about_text.forEach((aboutPara) => {
			if(aboutPara.id.toString() === event.target.getAttribute("value").toString()) {
				para = aboutPara;
	            return aboutPara;
	        }
		});

		if (forCtrler.location === 'ADMIN') {
			globalStore.dispatch(setAdminCurrAbout(para));
			globalStore.dispatch(setAdminModeAddMod("M"));

			globalStore.dispatch(setFAdmAbo_ti(para.title));
			globalStore.dispatch(setFAdmAbo_tf(para.title_force));
			globalStore.dispatch(setFAdmAbo_co(para.content));
			globalStore.dispatch(setFAdmAbo_ip(para.img_path));
			globalStore.dispatch(setFAdmAbo_op(para.order_place));
			globalStore.dispatch(setFAdmAbo_en(para.enable));
			globalStore.dispatch(setFAdmAbo_de(para.is_delete));
		}
	};

	const putTitleForce = (id, title_force, title, onClickAdmin, location) => {
		switch(title_force) {
			case 1 :
				return (<StyAboutTitleForceH2 location={location} onClick={onClickLocalAdmin} value={id}>{title}</StyAboutTitleForceH2>)
			case 2 :
				return (<StyAboutTitleForceH3 location={location} onClick={onClickLocalAdmin} value={id}>{title}</StyAboutTitleForceH3>)
			case 3 :
				return (<StyAboutTitleForceH4 location={location} onClick={onClickLocalAdmin} value={id}>{title}</StyAboutTitleForceH4>)
			case 4 :
				return (<StyAboutTitleForceH5 location={location} onClick={onClickLocalAdmin} value={id}>{title}</StyAboutTitleForceH5>)
			case 5 :
				return (<StyAboutTitleForceH6 location={location} onClick={onClickLocalAdmin} value={id}>{title}</StyAboutTitleForceH6>)
			case 6 :
				return (<StyAboutTitleForceH7 location={location} onClick={onClickLocalAdmin} value={id}>{title}</StyAboutTitleForceH7>)
			default:
				return (<StyAboutTitleForceH2 location={location} onClick={onClickLocalAdmin} value={id}>{title}</StyAboutTitleForceH2>)
		};
	};


	return {
		getAboutParagraph: getAboutParagraph,
		putTitleForce: putTitleForce,
	};
} 

export default About_ctrl;