
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
	MenuLeft,
	HelmetComp,
} from '../../compoment/';

import { Getter } from '../../../generaltools';

import {
	globalStore,
	getCurrentItem,
} from '../../../globalstate';

import {
	StyItemPlaceDiv,
	StyItemContentDiv,
	StyItemContentImg,
	// StyItemContentTitleH2,
	StyItemContentDescrP,
	StyItemContentTagP,
	StyItemAvailableSpan,
	StyGeneralPagePlaceDiv,
	StyGeneralPageH1,
	StyGeneralPageHeader,
	StyGeneralPageMain,
} from '../../../style/';

import { Item_ctrl } from '../../../controler/';

function Item({item, elementsTree}) {
	const initItem = {
		title: "",
		description: "",
		tag: "",
		path: "",
		carousel_order: 0,
		order: 0,
		available: true,
		enable: true,
		is_delete: false,
		category_id: 0,
	};

		const {id} = useParams();
	// let currentCategory = useSelector(getCurrentCategory(globalStore.getState())) || category_item || cate;

	let currentItem = useSelector(getCurrentItem(globalStore.getState())) || item || initItem;

	const forCtrler = {
		elementsTree: elementsTree,
		item: currentItem || item,
		idItem: id,
		initItem: initItem,
	};
	const ctrler = Item_ctrl(forCtrler);

	if (!currentItem || !currentItem.id) {
		currentItem = ctrler.getItem();
	}

	const headerPage = Getter.getHeaderPage({
			pageName: 'ITEM',
			categories: elementsTree.categories,
			categoryId: currentItem.category_id,
			items: elementsTree.items,
			itemId: currentItem.id,
		});

	return (
		<StyItemPlaceDiv>
			<HelmetComp
				title = {headerPage.title}
				description = {headerPage.descr}
				image = {headerPage.img} />
			<MenuLeft elementsTree={elementsTree} />
			<StyGeneralPagePlaceDiv>
				<article>
					<StyGeneralPageHeader>
							<StyGeneralPageH1>{headerPage.title}</StyGeneralPageH1>
							<p><StyItemAvailableSpan available={currentItem.available.toString()}>{currentItem.available === true ? 'DISPONIBLE' : 'DÉJÀ ENCRÉ'}</StyItemAvailableSpan></p>
					</StyGeneralPageHeader>
					<StyGeneralPageMain>
						<StyItemContentDiv>
							<picture>
								<StyItemContentImg src={Getter.getImgPath(currentItem.path)} alt={headerPage.title} />
							</picture>
						</StyItemContentDiv>
						<StyItemContentDescrP>{currentItem.description}</StyItemContentDescrP>
						<StyItemContentTagP>{currentItem.tag}</StyItemContentTagP>
					</StyGeneralPageMain>
				</article>
			</StyGeneralPagePlaceDiv>
		</StyItemPlaceDiv>
	);
} 

export default Item;