
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Getter } from '../../../generaltools';

import {
	globalStore,
	getAdmConnected,
	getAllSection,
	getAllCategory,
	getAllItem,
	getAdminInfos,
	getActuPara,
	getAboutParagraphs,
	getAdminPartActive,
	getAdminIsActive,
	getAdminCurrSection,
	getAdminCurrCategory,
	getAdminCurrItem,
	getAdminCurrAbout,
	getAdminModeAddMod,
} from '../../../globalstate';

import {
	AdminContent,
	AdminAbout,
	AdminInfo,
	AdminActu,
	AdminAddManageSection,
	AdminAddManageCategory,
	AdminAddManageItem,
	AdminAddManageAbout,
	AdminManageInfo,
	AdminManageActu,
	AdminConnect,
} from './';

import { HelmetComp } from '../../compoment/';

import { Admin_ctrl } from '../../../controler/';

import {
	StyAdminPlaceDiv,
	StyAdminTopDiv,
	StyAdminFormDiv,
	StyGeneralSpaceDiv,
} from '../../../style/';


function Admin() {

	const navigate = useNavigate();

	const admConnected = useSelector(getAdmConnected(globalStore.getState()));

	let sectionList = useSelector(getAllSection(globalStore.getState())) || [];
	let categoryList = useSelector(getAllCategory(globalStore.getState())) || [];
	let itemList = useSelector(getAllItem(globalStore.getState())) || [];
	let aboutText = useSelector(getAboutParagraphs(globalStore.getState())) || [];
	let admInfo = useSelector(getAdminInfos(globalStore.getState())) || {};
	let admActu = useSelector(getActuPara(globalStore.getState())) || {};

	const adminPartActive = useSelector(getAdminPartActive(globalStore.getState()));
	const adminIsActive = useSelector(getAdminIsActive(globalStore.getState()));
	const adminModeAddMod = useSelector(getAdminModeAddMod(globalStore.getState()));

	const adminContentSectionActive = useSelector(getAdminCurrSection(globalStore.getState()));
	const adminContentCategoryActive = useSelector(getAdminCurrCategory(globalStore.getState()));
	const adminContentItemActive = useSelector(getAdminCurrItem(globalStore.getState()));
	const adminContentAboutActive = useSelector(getAdminCurrAbout(globalStore.getState()));

	const maxOrdreAboutP1 = Getter.getMaxAboutOrderP1(aboutText);
	const maxOrdreSectionP1 = Getter.getMaxSectionOrderP1(sectionList);
	const maxOrdreCategoryP1 = Getter.getMaxCategoryOrderP1(categoryList, adminContentSectionActive);
	const maxOrdreItemP1 = Getter.getMaxItemOrderP1(itemList, adminContentCategoryActive);
	const maxCarouOrdreItemP1 = Getter.getMaxItemCarouOrderP1(itemList);

	const forCtrler = {
		navigate: navigate,
		admInfo: admInfo,
		admActu: admActu,
		adminContentSectionActive: adminContentSectionActive,
		adminContentCategoryActive: adminContentCategoryActive,
		maxOrdreAboutP1: maxOrdreAboutP1,
		maxOrdreSectionP1: maxOrdreSectionP1,
		maxOrdreCategoryP1: maxOrdreCategoryP1,
		maxOrdreItemP1: maxOrdreItemP1,
		maxCarouOrdreItemP1: maxCarouOrdreItemP1,
	};
	const ctrler = Admin_ctrl(forCtrler);
	
	return (
		<div>
			<HelmetComp
				title = {"Blue Virus - Administration"}
				description = {"Administration"}
				image = {""}
				noIndex = {true} />
			{ admConnected === false ? (<AdminConnect />) : (
					<StyAdminPlaceDiv>
						<StyAdminTopDiv>
							<AdminContent
								location={'ADMIN'}
								sectionList={sectionList}
								categoryList={categoryList}
								itemList={itemList}
								onClickSection={ctrler.handleClickOnSection}
								onClickCategory={ctrler.handleClickOnCategory}
								onClickItem={ctrler.handleClickOnItem}
								onClickAddSection={ctrler.handleClickOnAddSection}
								onClickAddCategory={ctrler.handleClickOnAddCategory}
								onClickAddItem={ctrler.handleClickOnAddItem}
								onClickContent={ctrler.handleClickOnContent}
								adminPartActive={adminPartActive} />
							<AdminAbout
								location={'ADMIN'}
								aboutText={aboutText}
								onClickAboutText={ctrler.handleClickOnAboutText}
								onClickAddAboutText={ctrler.handleClickOnAddAboutText}
								onClickAbout={ctrler.handleClickOnAbout}
								adminPartActive={adminPartActive} />
							<AdminInfo
								location={'ADMIN'}
								onClickInfo={ctrler.handleClickOnInfo}
								adminPartActive={adminPartActive} />
							<AdminActu
								location={'ADMIN'}
								onClickActu={ctrler.handleClickOnActu}
								adminPartActive={adminPartActive} />
						</StyAdminTopDiv>
						<StyGeneralSpaceDiv id="admManage"></StyGeneralSpaceDiv>
						<StyAdminFormDiv>
							{
								adminPartActive === 'CONTENT' ? (
									<div>
										{
											adminContentSectionActive && adminContentSectionActive !== {} && adminIsActive === 'SECTION' ? (
												<AdminAddManageSection addManage={adminModeAddMod} />
											) : (<div></div>)
										}
										{
											adminContentCategoryActive && adminContentCategoryActive !== {} && adminIsActive === 'CATEGORY' ? (
												<AdminAddManageCategory
													addManage={adminModeAddMod}
													sectionList={sectionList} />
											) : (<div></div>)
										}
										{
											adminContentItemActive && adminContentItemActive !== {} && adminIsActive === 'ITEM' ? (
												<AdminAddManageItem
													addManage={adminModeAddMod}
													sectionList={sectionList}
													categoryList={categoryList} />
											) : (<div></div>)
										}
									</div>
								) : (
									<div>
										{
											adminContentAboutActive && adminContentAboutActive !== {} && adminPartActive === 'ABOUT' ? (
												<AdminAddManageAbout addManage={adminModeAddMod} />
											) : (
												<div>
													{
														adminPartActive === 'INFO' ? (
															<AdminManageInfo />
														) : (
															<div>
																{
																	adminPartActive === 'ACTU' ? (
																		<AdminManageActu />
																	) : (<div></div>)
																}
															</div>
														)
													}
												</div>
											)
										}
									</div>
								)
							}
						</StyAdminFormDiv>
					</StyAdminPlaceDiv>
				)
			}
		</div>
	);
} 

export default Admin;