
import { Getter } from '../../../generaltools';

import { HelmetComp } from '../../compoment/';

function Siteplan({elementsTree}) {

	const headerPage = Getter.getHeaderPage({pageName: 'SITEPLAN'});

	return (
		<div>
			<HelmetComp
				title = {headerPage.title}
				description = {headerPage.descr}
				image = {headerPage.img} />
			<div>
				<h1>Plan du site</h1>
				<a href="/"><h2>Accueil</h2></a>
				<a href="/welcome"><h2>Accueil</h2></a>
				<a href="/actu"><h2>Actualités</h2></a>
				<a href="/about"><h2>A propos</h2></a>
				<a href="/contact"><h2>Contact</h2></a>
				<h2>Postes</h2>
				{
					elementsTree.sections.map((section) => {
						return (
							<div>
								<h3>{section.name}</h3>
								{
									elementsTree.categories.map((category) => {
										if (category.section_id === section.id) {
											let categoryLink = "/category/" + category.id;
											return (
												<div>
													<a href={categoryLink}><h4>{category.name}</h4></a>
													{
														elementsTree.items.map((item) => {
															if (item.category_id === category.id) {
																let itemLink = "/item/" + item.id;
																return (
																	<div>
																		<a href={itemLink}><h5>{item.title}</h5></a>
																	</div>
																);
															} else { return (<div></div>); }
														})
													}
												</div>
											);
										} else { return (<div></div>); }
									})
								}
							</div>
						);
					})
				}
			</div>
		</div>
	);
};

export default Siteplan;

